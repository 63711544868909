import React, { useState, useEffect } from 'react';
import './SchedulePopup.css';

const SchedulePopup = ({ stops, onClose }) => {
  const [email, setEmail] = useState('');
  const [station, setStation] = useState('');
  const [stationName, setStationName] = useState('');
  const [time, setTime] = useState('');
  const [line, setLine] = useState('');
  const [query, setQuery] = useState('');
  const [filteredStops, setFilteredStops] = useState(stops);
  const [error, setError] = useState('');
  const [successMessage, setSuccessMessage] = useState('');

  useEffect(() => {
    setFilteredStops(
      stops.filter(stop => stop.stopName.toLowerCase().includes(query.toLowerCase()))
    );
  }, [query, stops]);

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!email || !station || !stationName || !time || !line) {
      setError('All fields are required');
      return;
    }

    const requestData = { email, station, stationName, time, line };
    console.log('Sending request data:', requestData);

    try {
      const response = await fetch('https://srv571349.hstgr.cloud:8445/schedule-email', { 
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(requestData),
      });

      if (!response.ok) {
        const errorMessage = await response.text();
        console.error('Error from server:', errorMessage);
        setError(errorMessage);
      } else {
        const result = await response.text();
        setSuccessMessage(result);
        setTimeout(() => {
          setSuccessMessage('');
          onClose();
        }, 2000);
      }
    } catch (err) {
      console.error('Failed to fetch:', err);
      setError('Failed to connect to the server. Please try again later.');
    }
  };

  return (
    <div className="popup-overlay" onClick={onClose}>
      <div className="popup-content" onClick={(e) => e.stopPropagation()}>
        <button onClick={onClose} className="close-button">Close</button>
        <h2>Programare</h2>
        <form onSubmit={handleSubmit}>
          <label>
            Email:
            <input type="email" value={email} onChange={(e) => setEmail(e.target.value)} required />
          </label>
          <label>
            Stația:
            <div className="custom-select-container">
              <input
                type="text"
                value={query}
                onChange={(e) => setQuery(e.target.value)}
                placeholder="Căutați stația"
                className="custom-select-search"
              />
              <div className="custom-select-options">
                {filteredStops.map(stop => (
                  <div
                    key={stop.stopID}
                    className="select-option"
                    onClick={() => {
                      setStation(stop.stopID);
                      setStationName(stop.stopName);
                      setQuery(stop.stopName);
                    }}
                  >
                    {stop.stopName}
                  </div>
                ))}
              </div>
            </div>
          </label>
          <label>
            Ora:
            <input type="time" value={time} onChange={(e) => setTime(e.target.value)} required />
          </label>
          <label>
            Linia:
            <input type="text" value={line} onChange={(e) => setLine(e.target.value)} required />
          </label>
          {error && <p className="error">{error}</p>}
          <button type="submit">Trimite</button>
        </form>
        {successMessage && (
          <div className="success-message">
            {successMessage}
          </div>
        )}
      </div>
    </div>
  );
};

export default SchedulePopup;
